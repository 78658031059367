import { useState } from 'react'
import './App.css';
import moment from 'moment'
function App() {
  const [tiempos, cambiarTiempos] = useState([]);
  const dia = moment().format('DD')
  const mes = moment().format('MM')
  const ano = moment().format('YYYY')
  const valorHora = 6.046
  const agregarItem = () => cambiarTiempos([...tiempos, { desde: '12:00', hasta: "12:00" }])
  const deteleItem = (key) => cambiarTiempos(tiempos.filter((tiempo, id) => id !== key))
  const changeTime = (event, key) => {
    const { name, value } = event.target
    const tiemp = tiempos.map((tiempo, id) => id === key ? {...tiempo, [name]: value} : tiempo)
    cambiarTiempos(tiemp)
    calcularHoras()
  }
  const calcularHoras = () => tiempos.reduce((acc, tiempo) => acc +moment.duration(moment(`${ano}-${mes}-${dia}T${tiempo.hasta}:00`).diff(moment(`${ano}-${mes}-${dia}T${tiempo.desde}:00`))).asHours(), 0)
  return (
    <div className="App">
      <header className="App-header">
        <div style={{marginBottom: 10}}>Horas acumuladas: {calcularHoras()} horas - Valor aproximado {(calcularHoras() * valorHora).toFixed(2)} euros</div>
        <div className='times'>
          {
            !!tiempos.length && tiempos.map((tiempo, key) => {
              return (
                <div key={key} style={{display: 'flex', alignItems: 'center'}}>
                  <span style={{ marginRight: 10 }}>{key+1}.</span>
                  <input type="time" name='desde' value={tiempo.desde} onChange={(e) => changeTime(e, key)} />
                  <input type="time" name='hasta' value={tiempo.hasta} onChange={(e) => changeTime(e, key)} />
                  <i class="bi bi-trash" style={{cursor: 'pointer', marginLeft: 10}} onClick={() => deteleItem(key)}></i>

                </div>
              )
            })
          }
        </div>
        <div style={{cursor: 'pointer', marginTop: 15}} onClick={agregarItem}>+</div>
      </header>
    </div>
  );
}

export default App;
